import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDvWLZUH92RbJUs69bI8WUPkREhc9RHYEE",
  authDomain: "gather-tomorrow.firebaseapp.com",
  projectId: "gather-tomorrow",
  storageBucket: "gather-tomorrow.firebasestorage.app",
  messagingSenderId: "1035244428786",
  appId: "1:1035244428786:web:6e9029944a6e9fb60e1f6b",
  measurementId: "G-FF7LR72WWJ",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
