import { Typography, Layout, Row, Col, Affix, Image, Button, Menu } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { Context } from "./store/Context";
import ConsultantSearch from "./ConsultantSearch";
import MobileAppView from "./MobileAppView";
import {
  AppstoreOutlined,
  ContactsOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

const items = [
  {
    label: (
      <a href="/" rel="noopener noreferrer">
        Consultant Service
      </a>
    ),
    key: "consultant",
    icon: <ContactsOutlined />,
  },
  {
    label: (
      <a href="/app" rel="noopener noreferrer">
        Meowow App
      </a>
    ),
    key: "app",
    icon: <AppstoreOutlined />,
  },
];

function Mobile() {
  const { state } = useContext(Context);

  const [current, setCurrent] = useState(() =>
    window.location.pathname === "/" ? "consultant" : "other"
  );

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      setCurrent("consultant");
    } else if (window.location.pathname === "/app") {
      setCurrent("app");
    }
  }, []);

  const onClick = (e) => {
    console.log("click ", e);
    if (window.location.pathname === "/") {
      setCurrent("consultant");
    }
  };

  return (
    <Layout style={{ overflowX: "hidden" }}>
      <Affix offsetTop={1}>
        <Layout.Header style={{ background: "white", width: "100%" }}>
          <Row
            justify="center"
            align="middle"
            style={{ backgroundColor: "white", width: "100%", height: "100%" }}
          >
            <Col>
              <Image
                width={30}
                height={30}
                preview={false}
                src={state.appLogo}
              ></Image>
            </Col>
            <Col>
              <Typography.Title
                level={3}
                style={{ color: "black", marginLeft: "10px" }}
              >
                {state.appName}
              </Typography.Title>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={toggleCollapsed}
                style={{ marginLeft: 16 }}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </Button>
            </Col>
            {collapsed === false && (
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="inline"
                items={items}
                inlineCollapsed={collapsed}
              />
            )}
          </Row>
        </Layout.Header>
      </Affix>

      <Layout.Content>
        {window.location.pathname === "/" ? (
          <ConsultantSearch />
        ) : (
          <MobileAppView />
        )}
      </Layout.Content>
    </Layout>
  );
}

export default Mobile;
